import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import "./css/globals.css";
import "./css/print.css";
import "./css/layout.css";

import Header from "./header"
import Banner from "./banner"
import Cookie from "./cookie"
import Footer from "./footer"

const isBrowser = typeof window !== "undefined";
var resizeObserver;

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cellsize: 25,
      clientWidth: 0,
    };
    this.setGridSize = this.setGridSize.bind(this);
  }

  componentDidMount() {
    if (isBrowser) {

      window.addEventListener('resize', this.setGridSize, true)

      resizeObserver = new ResizeObserver(entries => {
        this.setGridSize();
      });
      resizeObserver.observe(document.scrollingElement);
      this.setGridSize();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      this.setGridSize();
      return false
    }

    if (this.state !== nextState) {
      return true
    }

    return false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clientWidth !== document.body.clientWidth) {
      let t = this;
      t.setGridSize();
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener('resize', this.setGridSize, true);
      resizeObserver.disconnect();
    }
  }

  setGridSize(e) {
    if (isBrowser) {
      let cellsize = 0;
      let width = window.innerWidth
      let innerWidth = document.body.clientWidth

      if (width > 960) {
        cellsize = innerWidth / 48
      }
      else if (width > 480) {
        cellsize = innerWidth / 24
      }
      else {
        cellsize = innerWidth / 12
      }

      cellsize = (Math.round(cellsize * 100) / 100).toFixed(2)
      this.setState({ cellsize: cellsize, clientWidth: innerWidth}, () => {
        document.documentElement.style.setProperty('--cell', cellsize + 'px');
      });
    }
  }

  render() {
    const { cellsize } = this.state;

    return (
      <div className="layout-container">

        <svg className="layout-grid" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="grid" patternUnits="userSpaceOnUse" width={cellsize} height={cellsize}>
              <path fill="none" stroke="#002561" strokeWidth="2" d={'M ' + cellsize + ' 0 L 0 0 0 ' + cellsize}></path>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)"></rect>
        </svg>

        <Helmet htmlAttributes={{
          lang: 'nl',
        }}>
          <script src="https://unpkg.com/@webcomponents/webcomponentsjs@2.4.3/webcomponents-loader.js"></script>
          <script
            async
            src="https://unpkg.com/wired-elements@2.0.5/lib/wired-elements-bundled.js"
          ></script>
        </Helmet>

        <Banner></Banner>

        <Cookie></Cookie>

        <StaticQuery
          query={graphql`
            query HeadingQuery {
              site {
                siteMetadata {
                  title
                  menuLinks {
                    name
                    link
                    style
                    target
                  }
                }
              }
            }
          `}
          render={(data) => (
            <>
              <Header menu={data.site.siteMetadata.menuLinks} location={this.props.children.props}></Header>
              <div id="main-content" className="main-content">
                {this.props.children}
              </div>
              <Footer menu={data.site.siteMetadata.menuLinks} location={this.props.children.props}></Footer>
            </>
          )}
        />
      </div>
    )
  }
}

export default Layout;
