import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./css/banner.css"

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissed: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    let t = this
    // Hide banner.
    e.target.parentElement.classList.add('hide');
    setTimeout(function () {
      e.target.parentElement.style.display = 'none';
      t.setState({
        dismissed: true
      });
    }, 300);
  }

  render() {
    if (this.state.dismissed) {
      return null
    }

    return (
      <StaticQuery
        query={graphql`
            query BannerQuery {
              allSitewideAlertSitewideAlert {
                nodes {
                  message {
                    value
                  }
                  scheduled_date {
                    value
                    end_value
                  }
                  scheduled_alert
                  status
                  style
                }
              }
            }
          `}
        render={(query) => {
          let showBanner = true;
          let data = query.allSitewideAlertSitewideAlert.nodes[0];

          if (data.scheduled_alert) {
            showBanner = false;

            let now = Date.now();
            let startDate = Date.parse(data.scheduled_date.value);
            let endDate = Date.parse(data.scheduled_date.end_value);

            if (now >= startDate && now <= endDate) {
              showBanner = true;
            }
          }

          if (showBanner) {
            let style = data.style;
            let content = data.message.value;
            return (
              <div className={`banner banner__style--${style}`}>
                <button className="banner--close" onClick={this.handleClick}>
                  Close
                  <span className="banner--close-dash"></span>
                  <span className="banner--close-dash"></span>
                </button>
                <div className="banner__content" dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
            )
          }
          return null;
        }}
      />
    )
  }
}

export default Banner
