import React from "react"
import { Link } from "gatsby"

import Logo from "../images/logo.svg";
import SocialMenu from "./menu/social.js";
import LegalMenu from "./menu/legal.js";

import "./css/header.css"

const isBrowser = typeof window !== "undefined";
let lastScrollTop = 0;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavbarOpen: false,
      header: null,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.handleSticky = this.handleSticky.bind(this);
  }

  componentDidMount() {
    if (isBrowser) {
      let header = document.querySelector('header');
      this.setState({header: header
      }, () => {
        window.addEventListener('scroll', this.handleSticky, true)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (isBrowser) {
      if (this.props.location.path !== prevProps.location.path) {
        this.closeNav();
        this.handleSticky()
      }
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener('scroll', this.handleSticky, true);
    }
  }

  handleToggle = () => {
    this.setState({ isNavbarOpen: !this.state.isNavbarOpen }, () => {
      if (this.state.isNavbarOpen) {
        document.scrollingElement.classList.add("nav-open");
      }
      else {
        document.scrollingElement.classList.remove("nav-open");
      }
    });
  }

  closeNav = () => {
    this.setState({ isNavbarOpen: false }, () => {
      if (this.state.isNavbarOpen) {
        document.scrollingElement.classList.add("nav-open");
      }
      else {
        document.scrollingElement.classList.remove("nav-open");
      }
    });
  }

  handleSticky = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    let t = this;

    if (t.state.header.classList.contains('sticky') && st > 0 && st > lastScrollTop) {
      this.state.header.classList.remove('sticky')
    } else if (!t.state.header.classList.contains('sticky') && st < lastScrollTop) {
      t.state.header.classList.add('sticky')
    }

    lastScrollTop = st <= 0 ? 0 : st;
  }

  render() {
    const menuLinks = this.props.menu;
    let location = this.props.location.location.pathname;

    return (
      <header className={this.state.isNavbarOpen ? "header sticky active" : "header sticky"}>

        <Link onClick={this.closeNav} to="/" className="header--logo" title="Go to the homepage" rel="home">
          <Logo />
          <span className="hidden">MAS Alles van waarde</span>
        </Link>

        <div className={this.state.isNavbarOpen ? "header__nav active" : "header__nav"}>
          <div className="header__nav--wrapper">
            <ul className="header__nav--list">
              {menuLinks.map((link, index) => {
                var className = 'header__nav--link';

                if (link.style) {
                  className += ' ' + link.style;
                }

                if (link.disabled) {
                  className += ' disabled';
                }

                if (link.target) {
                  return (
                    <li key={index} className="header__nav--item">
                      <a onClick={this.closeNav} target="_blank" rel="noreferrer" className={className} href={link.link}>
                        {link.name}
                      </a>
                    </li>
                  )
                }

                return (
                  <li key={index} className="header__nav--item">
                    <Link onClick={this.closeNav} className={className} to={link.link}>
                      {link.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <div className="header__nav--mobile">
              <LegalMenu />
              <div className="header__nav--link-block-wrapper">
                <a className="header__nav--link header__nav--link-block" rel="noreferrer" onClick={this.closeNav} target="_blank" href="http://eepurl.com/hFIixX">
                  <span>Meld je aan voor de nieuwsbrief van ErfgoedLab Antwerpen</span>
                </a>
                <a className="header__nav--link header__nav--link-block" rel="noreferrer" onClick={this.closeNav} target="_blank" href="https://mas.be/nl/#block-mas-museasubscribe-block">
                  <span>Meld je aan voor de nieuwsbrief van het MAS</span>
                </a>
              </div>
              <SocialMenu />
            </div>
          </div>
        </div>

        <div className="nav__toggler--wrapper">
          {menuLinks.map((link, index) => {
            if (location === null) {
              return null;
            }

            let currLink = link.link;

            let location_array = location.split("/");
            location_array = location_array.filter(item => item);

            if (currLink === location) {
              return (
                <Link key={index} className="nav__toggler--link" to={currLink}>
                  <span>{link.name}</span>
                </Link>
              )
            }
            else if (index === 0 && location_array.includes("groepen") && location_array.length > 1) {
              let backLink = '/groepen/'
              if (this.props.location.location.state) {
                backLink = this.props.location.location.state.prevPath
              }

              return (
                <Link to={backLink} key={index} className="nav__toggler--link nav__toggler--link--small">
                  <span>Naar overzicht</span>
                </Link>
              )
            }
            else {
              return null;
            }

          })}
          <button onClick={this.handleToggle} className={this.state.isNavbarOpen ? "nav__toggler active" : "nav__toggler"} title="Menu">
            <span className="nav__toggler--dashes">
              <span className="nav__toggler--dash"></span>
              <span className="nav__toggler--dash"></span>
              <span className="nav__toggler--dash"></span>
            </span>
            <span className="nav__toggler--title">{this.state.isNavbarOpen ? "Sluit" : "Menu"}</span>
          </button>
        </div>

      </header>
    )
  }
}

export default Header
