import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import "./css/legal.css"

class Legal extends Component {
  render() {

    return (
      <div className="legal__nav">
        <StaticQuery
          query={graphql`
            query LegalMenuQuery {
              site {
                siteMetadata {
                  legalLinks {
                    link
                    name
                  }
                }
              }
            }
          `}
          render={(data) => (
            <>
              {data.site.siteMetadata.legalLinks.map((link, index) => (
                <a key={index}
                  className="legal__nav--link"
                  href={link.link}
                  target="_blank"
                  rel="noreferrer">
                  <span>{link.name}</span>
                </a>
              ))}
            </>
          )}
        />
      </div>
    )
  }
}

export default Legal
