import React from "react"
import { CookieBanner } from '@palmabit/react-cookie-law';

import "./css/cookieBanner.css";

const Cookie = () => {

  return (
    <div className="cookie__banner">
      <CookieBanner
        message={
          <span>Deze website gebruikt <a href="https://mas.be/nl/cookies" target="_blank" rel="noreferrer">cookies</a></span>
        }
        acceptButtonText="Aanvaard alle cookies"
        managePreferencesButtonText="Pas je voorkeuren aan"
        necessaryOptionText="Essentiële cookies"
        statisticsOptionText="Statistische cookies"
        showPreferencesOption={false}
        showStatisticsOption={true}
        showMarketingOption={false}
        marketingOptionText="Marketing cookies"
        savePreferencesButtonText="Voorkeuren opslaan"
        styles={{
          dialog: {
            zIndex: '800',
          },
          container: {
            position: 'relative',
          },
          message: {
            position: 'relative',
          },
          policy: {
            display: 'none'
          },
          selectPane: {
            position: 'relative',
          },
          optionWrapper: {
            position: 'relative',
          },
          checkbox: {
            position: 'relative',
          },
          optionLabel: {
            position: 'relative',
          },
          buttonWrapper: {
            display: 'block',
          },
          button: {
            display: 'block',
          },
        }}
      />
    </div>
  )
}

export default Cookie
