import React, { Component } from "react"
import { Link } from "gatsby"

import "./css/footer.css"

import Logo from "../images/logo.svg";
import LogoMas from "../images/logo-mas.svg";
import LogoA from "../images/logo-a.svg";
import LegalMenu from "./menu/legal.js";

class Footer extends Component {
  render() {
    let pathPrefix = '';
    const menuLinks = this.props.menu;
    let path = this.props.location.uri;

    if (path !== undefined && path.split('/').includes("amp")) {
      pathPrefix = '/amp'
    }

    return (
      <footer className="footer">

        <Link to={pathPrefix + "/"} className="footer--logo" title="Go to the homepage" rel="home">
          <Logo />
          <span className="hidden">MAS Alles van waarde</span>
        </Link>

        <a href="https://mas.be/nl/content/alles-van-waarde" rel="noreferrer" target="_blank" className="footer--big-link">
          <span>Bezoek de expo</span>
        </a>

        <div className="footer__block">

          <div className="footer__block--links">
            <a className="footer__block--link"
            target="_blank"
            rel="noreferrer"
            href="http://eepurl.com/hFIixX">
              <span>Meld je aan voor de nieuwsbrief van ErfgoedLab Antwerpen</span>
            </a>

            <a className="footer__block--link"
            target="_blank"
            rel="noreferrer"
            href="https://mas.be/nl/#block-mas-museasubscribe-block">
              <span>Meld je aan voor de nieuwsbrief van het MAS</span>
            </a>
          </div>
          <LegalMenu />

          <div className="footer__menu">
            {menuLinks.map((link, index) => {
              var className = 'footer__nav--link';

              if (link.style) {
                return null;
              }

              if (link.disabled) {
                className += ' disabled';
              }

              return (
                <Link key={index} onClick={this.closeNav} className={className} to={pathPrefix + link.link}>
                  {link.name}
                </Link>
              )
            })}
          </div>

          <div className="footer--info">
            <span>Dit is een online expo van ErfgoedLab Antwerpen en MAS.</span><br />
            <LogoA /><LogoMas />
          </div>

          <div className="footer--cred">
            <span>&copy;</span> { new Date().getFullYear() } stad Antwerpen
            <LegalMenu />
          </div>

        </div>

      </footer>
    )
  }
}

export default Footer
